import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

const ClientCard = ({ client }) => {
  let clientImage = ''
  switch (client.id_image) {
    case '1':
      clientImage = (
        <StaticImage
          src={`../../assets/images/clients/client1.png`}
          alt={client.name}
        />
      )
      break
    case '2':
      clientImage = (
        <StaticImage
          src={`../../assets/images/clients/client2.png`}
          alt={client.name}
        />
      )
      break
    case '3':
      clientImage = (
        <StaticImage
          src={`../../assets/images/clients/client3.png`}
          alt={client.name}
        />
      )
      break
    case '4':
      clientImage = (
        <StaticImage
          src={`../../assets/images/clients/client4.png`}
          alt={client.name}
        />
      )
      break
    case '5':
      clientImage = (
        <StaticImage
          src={`../../assets/images/clients/client5.png`}
          alt={client.name}
        />
      )
      break
    case '6':
      clientImage = (
        <StaticImage
          src={`../../assets/images/clients/client6.png`}
          alt={client.name}
        />
      )
      break
    default:
      clientImage = (
        <StaticImage
          src={`../../assets/images/clients/default.png`}
          alt={client.name}
        />
      )
      break
  }

  const handleClick = () => {
    if (client.url) {
      window.open(client.url, 'blank')
    }
  }

  return (
    <div className="clients__card">
      <button
        className="clients__image"
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick()
          }
        }}
        tabIndex="0"
      >
        {client.id_image && clientImage}
      </button>
      <div className="clients__quote">
        <p className="text-primary secondary font-medium">
          {client.quote && client.quote}
        </p>
        <p className="clients__info text-primary primary">
          {client.name && client.name}, {client.company && client.company}
        </p>
      </div>
    </div>
  )
}

const Clients = () => {
  const { t } = useTranslation()

  const client_list = t('page_home_section_clients_list', {
    returnObjects: true,
  })

  return (
    <section className="clients">
      <div className="container ">
        <h2 className="clients__title">
          {t('page_home_section_clients_title')}
        </h2>
        <div className="clients__container">
          {client_list.map((client, index) => (
            <ClientCard key={index} client={client} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Clients
